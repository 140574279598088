import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axiosInstance from '../../utils/axios';
//
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter:{
    year : null,
  },
  academicYears : [],
  dataBackUp : [],
};

const slice = createSlice({
  name: 'makeUpMoney',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilters(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getAcademicYears(state, action) {
        state.isLoading = false
        state.academicYears = action.payload
    },
    getDataBackUp(state, action) {
      state.isLoading = false
      state.dataBackUp = action.payload
    },
    resetDataBackup (state){
      state.dataBackUp = [];
    }
  },
});

export function setFilter(params){
  return async (dispatch) => {
    dispatch(slice.actions.setFilters(params))
  }
}
export function ResetDataBackup(params){
  return async (dispatch) => {
    dispatch(slice.actions.resetDataBackup(params))
  }
}
export function AcademicYears() {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`get-all-admin-academicdate`, {
        headers: {
          APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getAcademicYears(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function DataBackUpMoney(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`get-school-backup-income`,params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getDataBackUp(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function CreateBackUpMoney(params) {
  return async () => {
    try {
      const res = await axiosInstance.post(`create-edit-school-backup`,params, {
        headers: {
          APPKEY: appKey,
        },
      });
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export default slice.reducer;
