import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  filter: {
    firstName: '',
    lastName: '',
    code: '',
    student: '',
  },
  listGuardian: [],
  listFree: [],
  listInvoiceGuardian: [],
  DataStudent: [],
  listPaymentGuardian: [],
};

const slice = createSlice({
  name: 'guardianActive',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    getSchoolSuccess(state, action) {
      state.listSchool = action.payload;
    },
    getSearchGuardianSuccess(state, action) {
      state.isLoading = false;
      state.listGuardian = action.payload;
    },
    updatePaymentStatus(state, action) {
      state.listGuardian = state.listGuardian?.map((guardian) => {
        if (guardian.id === action.payload.id) {
          return { ...guardian, payment_status: action.payload.payment_status, expired_at: action.payload.expired_at };
        }
        return guardian;
      });
    },
    getListFree(state, action) {
      state.listFree = action.payload;
    },
    getListInvoice(state, action) {
      state.listInvoiceGuardian = action.payload;
    },
    resetDataGuardian(state) {
      state.listGuardian = [];
    },
    getDataStudent(state, action) {
      state.DataStudent = action.payload;
    },
    addStudent(state, action) {
      state.listGuardian = state.listGuardian?.map((guardian) => {
        if (guardian.id === action.payload.id) {
          console.log('new Item ==>', { ...guardian, students: [...action.payload.students] });
          return { ...guardian, students: [...action.payload.students] };
        }
        return guardian;
      });
    },
    deleteStudent(state, action) {
      state.listGuardian = state.listGuardian?.map((guardian) => {
        if (guardian.id === action.payload.guardian_id) {
          const newChild = guardian?.students?.filter((student) => {
            return student?.id !== action.payload.student_id;
          });
          return { ...guardian, students: newChild };
        }
        return guardian;
      });
    },
    getListHistoryPayment(state, action) {
      state.isLoading = false;
      state.listPaymentGuardian = action.payload;
    },
  },
});

export function onSetFilter(body) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilter(body));
  };
}
export function ResetDataGuardian() {
  return async (dispatch) => {
    dispatch(slice.actions.resetDataGuardian());
  };
}
export function searchGuardian(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let res = null;
    try {
      res = await axiosInstance.post(`check-guardian-has-student`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getSearchGuardianSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      return error;
    }
  };
}
export function addPaymentGuardian(params) {
  return async () => {
    let res = null;
    try {
      res = await axiosInstance.post(`adminAddGuardianPayment`, params, {
        headers: {
          APPKEY: appKey,
        },
      });

      console.log('res', res?.data);
      if (res?.data?.data) {
        dispatch(
          slice.actions.updatePaymentStatus({
            id: params?.guardian_id,
            payment_status: true,
            expired_at: res?.data?.data?.expired_at,
          })
        );
      }
      return res;
    } catch (error) {
      console.log(error);
      return res;
    }
  };
}
export function getListFreeGuardian(params) {
  console.log('param', params);
  return async () => {
    let res = null;
    try {
      res = await axiosInstance.get(`list-request-company-types`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getListFree(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return res;
    }
  };
}
export function GetDataStudent(param) {
  return async (dispatch) => {
    let res = null;
    try {
      res = await axiosInstance.post(`search-student`, param, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getDataStudent(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      res = error;
      return res;
    }
  };
}
// Connect guardian to student
export function addGuardianToStudent(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`add-student-to-guadian`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.addStudent(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function removeStudent(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`remove-student-guardian`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log('Res data delete===>', params);
      if (res?.data?.status === true) {
        dispatch(slice.actions.deleteStudent(params));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
// History payment
export function listHistoryPayment(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`get-payment-history`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getListHistoryPayment(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export default slice.reducer;
