import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../utils/axios';
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  selectYearMonth: {
    years: null,
    months: null,
  },

  // End Payment All School

  // Start Payment By School
  filter: {
    // Get data by level
    levels: null,
    dates: null,
    years: '',
    // Get data by year
    yearly: '',
    money: null,
  },
  // End Payment By School
  // Start Data Guardian And Payment
  optionSchoolData: [],
  optionYear: [],
  optionGrade: [],
  filterData: {
    years: null,
    levels: null,
    schoolsGuardian: null,
    yearsGuardian: null,
    levelsGuardian: null,
  },
  // End Data Guardian And Payment
};

const slice = createSlice({
  name: 'listExcel',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    selectFilterData(state, action) {
      state.filterData = action.payload;
    },
    selectedFilter(state, action) {
      state.filter = action.payload;
    },
    getSchoolsSuccess(state, action) {
      // state.isLoading = false;
      state.optionSchools = action.payload;
    },
    getOptionSchoolData(state, action) {
      state.optionSchoolData = action.payload;
    },
    getOptionYearData(state, action) {
      state.optionYear = action.payload;
    },
    getOptionGradeData(state, action) {
      state.optionGrade = action.payload;
    },
  },
});

export function selectedFilterSuccess(params) {
  return async (dispatch) => {
    dispatch(slice.actions.selectedFilter(params));
  };
}
export function selectedFilterDataSuccess(params) {
  return async (dispatch) => {
    dispatch(slice.actions.selectFilterData(params));
  };
}

// Start Data Guardian and Student
export function getYearDataSuccess(schoolId) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`get-all-academicdate/${schoolId}`);
      console.log(res);
      if (res?.status === 200) {
        dispatch(slice.actions.getOptionYearData(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function getGradDataSuccess(id) {
  let res = '';
  return async (dispatch) => {
    try {
      res = await axiosInstance.post('get-schoolyear-all-grade', id, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.status === 200) {
        dispatch(slice.actions.getOptionGradeData(res?.data?.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return res;
  };
}
// End Data Guardian and Student

export const onExportGuardianPaymentAllSchool = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: 'export-all-guardian-payment-list',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Guardian Payment All School.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const onExportGuardianPaymentTemplate = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: 'export-guardian-payment',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Guardian Payment.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    // dispatch(hasError(error));
    return error;
  }
};
export const onExportListStudentTemplate = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: 'export-student-list',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'List Student.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const onExportListGuardianTemplate = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: 'export-guardian-list',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'List Guardian.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    return error;
  }
};
// Back up money data excel
export const onExportGuardianPaymentAllSchoolBackup = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: 'export-all-guardian-fake-payment-list',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Guardian Payment All School Backup.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const onExportGuardianPaymentTemplateBySchoolBackup = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: 'export-backup-guardian-payment',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Guardian Payment By School Backup.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    return error;
  }
};
// Excel payment data guardian by yearly
export const onExportGuardianPaymentByYear = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: 'export-guardian-payment-monthly',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Guardian Payment By School.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    return error;
  }
};

export default slice.reducer;
