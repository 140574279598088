import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils
import axiosInstance from '../../utils/axios'
//
import { dispatch } from '../store';
// config

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
    isLoading: false,
    filter: {
       school : null
    },
    listSchools : [],
  };
  
  const slice = createSlice({
    name: 'listSchools',
    initialState,
    reducers: {
      startLoading(state) {
        state.isLoading = true;
      },
      stopLoading(state) {
        state.isLoading = false;
      },
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
      setFilterSuccess(state, action) {
        state.isLoading = false;
        state.filter = action.payload;
      },
      getListSchools(state, action) {
        state.isLoading = false;
        state.listSchools = action.payload;
      }
      
    },
});
export function setFilter(params){
    return async (dispatch) => {
        dispatch(slice.actions.setFilterSuccess(params));
    }
}

export function getSchoolDataSuccess() {
    return async (dispatch) => {
      try {
        const res = await axiosInstance.get(`list-customer-school-admin`, {
          headers: {
            APPKEY: appKey,
          },
        });
        if (res?.status === 200) {
          dispatch(slice.actions.getListSchools(res?.data?.data));
        }
        return res;
      } catch (error) {
        console.log(error);
        return error;
      }
    };
}

export default slice.reducer;