import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// @mui
import { Button, List, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// locales
import { useLocales } from '../../../locales';
import { useAuthContext } from '../../../auth/useAuthContext';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
//
import { StyledSubheader } from './styles';
import NavList from './NavList';


// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();
  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  // const handleLogout = async () => {
  //   setIsLoading(true)
  //   try {
  //     logout();
  //     navigate(PATH_AUTH.login, { replace: true });
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar('Unable to logout!', { variant: 'error' });
  //   }
  //   setIsLoading(false)
  // };
  

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        // const key = group.subheader || group.items[0].title;
        const key = group.title || group.children[0].title
        
        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {/* {group.subheader && <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>} */}

            {/* {group.items.map((list) => (
              <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
            ))} */}

            {/* Add new dynamic menu tree */}
            <NavList key={group.title + group.path} data={group} depth={1} hasChild={!!group.children} />
          </List>
        );
      })}
      {/* <LoadingButton
              // fullWidth
        loading={isLoading}
        variant='contained'
        size='small'
        sx={{display:'center', justifyContent:'center', m:2}}
        onClick={handleLogout}
      >
        Logout
      </LoadingButton> */}
    </Stack>
  );
}
