import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axiosInstance from '../../utils/axios';
//
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter: {
    // academicYear: null,
    // level: null,
    // class: null,
  },
  academicYearData: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSuccess(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getAcademicYearSuccess(state, action) {
      state.isLoading = false;
      state.academicYearData = action.payload;
    },
  },
});

export function setFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function setSelectedFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function getAcademicYearData(schoolId) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`get-all-academicdate/${schoolId}`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(response);
      if (response?.data?.data) {
        dispatch(slice.actions.getAcademicYearSuccess(response?.data?.data));
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export default slice.reducer