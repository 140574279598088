// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  HEADER: {
    TITLE: `CAMEMIS SYSTEM`,
  },
  SCHOOL_DATA: {
    TITLE: 'School Data',
    SCHOOL: 'Please select school',
    ACADEMIC_YEARS: 'Please select academic year',
    ENTER_LEVEL: 'Please enter level',
    ENTER_YEARS: 'Please enter academic year',
    ACADEMIC_YEAR: 'Academic year',
    LEVEL: 'Level',
    CLASS: 'Class',
    LAST_NAME: 'Last name (Khmer)',
    FIRST_NAME: 'First name (Khmer)',
    LAST_NAME_EN: 'Last name (Latin)',
    FIRST_NAME_EN: 'First name (Latin)',
    ENTER_CODE: 'Please enter code',
    STUDENT: {
      CODE: 'Code',
      CLASSROOM: 'Classroom',
      DOB: 'Dob',
      PHONE_NUMBER: 'Phone number',
      NOT_GUARDIAN: 'Not yet connected guardian',
      S_LAST_NAME: 'Student last name',
      S_FIRST_NAME: 'Student first name',
      S_CODE: 'Student code',
      S_LIST: 'Student list',
      MAZER_CLASS: ' Class Mazer',
      MAZER: 'Mazer',
      FULLNAME_LATIN: 'Name Latin',
    },
    GUARDIAN: {
      INFORMATION: 'Guardian information',
      GUARDIAN: 'Guardian',
      EMPTY_DATA: 'Empty data',
      CODE: 'Code',
      PHONE_NUMBER: 'Phone number',
      DOB: 'Dob',
      EXPIRED: 'Expired_at',
      G_LAST_NAME: 'Guardian last name',
      G_FIRST_NAME: 'Guardian first name',
      G_CODE: 'Guardian code',
      G_LIST: 'Guardian list',
    },
    STAFF: {
      FULLNAME_LATIN: 'Name latin',
      CODE: 'Code',
      PHONE_NUMBER: 'Phone number',
      DOB: 'Dob',
    },
  },
  BUTTON: {
    SEARCH: 'Search',
    OK: 'OK',
    DETAIL: 'Detail',
    RESET_PASSWORD: 'Reset password',
    CREATE: 'Create',
    CONNECT_GUARDIAN: 'Connect guardian to student',
    EXCEL: 'Export to excel',
    EXCEL_PAYMENT_ALL_SCHOOL: 'Guardian payment data all schools',
    EXCEL_PAYMENT_ALL_SCHOOL_Backup: 'Guardian payment data all schools backup',
    DATA_STUDENT: 'Student list',
    DATA_GUARDIAN: 'Guardian list',
    SHOW_DATA: 'Show',
    PRINT_PDF: 'Export to PDF',
    ASSIGN_MAZER: 'Assign class president',
    DELETE: 'Delete',
    REMOVE: 'Remove',
    CANCEL: 'Cancel',
    FILTER: 'Search name',
    SAVE_CHANGE: 'Save Change',
    EDIT: 'Edit',
    ADD_MORE: 'Create',
    SAVE: 'Save',
    DOWNLOAD: 'Download as PDF',
  },
  EMPTY_CONTENT: {
    CONTENT: 'Please Input information above !',
    NOT_DATA: 'Do not have data',
    DATA_LOADING: 'Data is Loading',
  },
  CHECK_BOX: {
    CHOOSE: 'Please choose',
  },
  EXCEL: {
    ENTER_YEAR: 'Please enter the school year',
    CHOOSE_MONTH: 'Please select month',
    DATE: 'DD/MM/YY',
    START_DATE: 'Start : DD/MM/YY',
    END_DATE: 'End : DD/MM/YY',
    DATA_PAYMENT_BY_SCHOOL_ONCE_DAY: 'Guardian payment data in school (one day)',
    DATA_PAYMENT_BY_SCHOOL_ONCE_DAY_BACKUP: 'Guardian payment data in school back up (one day)',
    DATA_PAYMENT_BY_SCHOOL_MORE_MONTH: 'guardian payment data in school (more month)',
    DATA_PAYMENT_BY_SCHOOL_MORE_MONTH_BACKUP: 'guardian payment data in school backup (more month)',
  },
  REVENUE: {
    ACADEMIC_YEAR: 'Please select academic year',
    EVENT_ID: 'Please select Date',
    EVENT_NAME: 'Please input name',
    YEAR: 'Please select year',
    TOP_SCHOOL: {
      TABLE_TOP: 'Table top',
      LINE: 'Line Chart',
      N0: 'N0',
      TOP: 'Top school sale',
      QTY: 'QTY',
      REVENUES: 'Revenue',
      PERCENT: 'Percent %',
      MONTH: 'Month',
      SEMESTER: 'Sememster',
      YEAR: 'Year',
      STUDENT_AMOUNT: 'Student amount',
    },
  },
  MODAL: {
    ASSIGN_CLASS_MONITOR: 'Do you want assign class monitor?',
    REMOVE_CLASS_MONITOR: 'Do you want remove class monitor?',
    CLASS_MONITOR: 'Class monitor',
    PRICE_PAYMENT: 'Payment fee',
    TYPE_PAYMENT: 'Type of payment',
    MONTH: 'Month',
    COMMISION_PLAN: 'Create commission plan',
    DELETE_COMMISION_PLAN: 'Do you want to delete',
    TITLE_COMMISION_PLAN: 'Commission plan',
    EDIT_GUARDIAN: 'Edit Guardian',
    EDIT_Staff: 'Edit Staff',
  },

  FEE_SETTING: {
    TABLE: {
      PRICE: 'Price',
      OPTION: 'Option',
      ACTION: 'Action',
    },
    COMMISION_PLAN: {
      NAME: 'Name',
      PERCENT: 'Percent',
      ACTION: 'Action',
    },
    TEACHER_COMMISION: {
      SELECT_TEACHER: 'Please choose teacher name',
      SELECT_STUDENT: 'Please choose student name',
      SELECT_METHOD: 'Please choose payment method',
      SELECT_OPTION: 'Please choose payment option',
    },
  },
  BACK_UP_MONEY: {
    CARD_CREATE_BACK_UP: {
      CREATE_BACK_UP: 'Create Transaction',
    },
    CARD_SHOW_BACK_UP: {
      SHOW_BACK_UP: 'After backup of cash',
      AFTER_BACK_UP: 'Back up Cash',
      PERCENT: 'Perccent',
      //
      REAL_TOTAL_GUARDIAN: ' Real total guardian',
      REAL_TOTAL_GUARDIAN_PAID: 'Real total paid',
      WITHDRAWAL: 'Withdrawal',
      MONTHLY: 'monthly',
      SEMESTER: 'semester',
      YEARLY: 'yearly',
      FAKE_TOTAL_GUARDIAN: 'Fake total guardian',
      FAKE_TOTAL_PAID: 'Fake total fake',
    },
    BAR_CHART: {
      REAL_TOTAL_INCOME: 'Real total income',
      FAKE_TOTAL_INCOME: 'Fake total income',
      //
      REAL_CASH: 'Real cash',
      FAKE_CASH: 'Backup cash',
    },
    BUTTON: {
      GENERATE: 'Determine the amount',
    },
  },
  REQUEST_SCHOOL: {
    LIST_REQUEST: {
      LIST_REQUEST_FREE: 'List request using free',
      NO: 'No',
      GUARDIAN_FULLNAME: 'Guardian fullname',
      STUDENT_FULLNAME: 'Student fullname',
      SITUATION: 'Situation',
      REQUEST_BY: 'Request by',
      REFERENCE: 'Reference',
      CHOOSE: 'Choose',
      CLASS: 'Class',
    },
    LIST_APPROVED: {
      LIST_APPROVED_FREE: 'List approved using free',
      STATUS: 'Staus',
    },
    LIST_REJECTED: {
      LIST_REJECTED_FREE: 'List rejected using free',
    },
    LIST_CASE: {
      CASE: 'Case',
      PAYMENT_METHOD: 'Case',
      PERIOD: 'Period',
      DATE: 'Date',
      PRINT: 'Print',
      EXPORT_TO_EXCEL: 'Export to Excel',
      NUMBER_CHILD: 'Number of child',
      DOB: 'Date of birth',
      GENDER: 'Gender',
    },
  },
};

export default en;
