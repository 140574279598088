import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axiosInstance from '../../utils/axios';
//
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter: {
    academicYear: null,
    class: null,
    firstName: '',
    lastName: '',
    code: '',
  },
  listAcademicYears: [],
  listClass: [],
  listStudents: [],
  dataMazer: [],
};

const slice = createSlice({
  name: 'mazer',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSucces(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getAcademicYearSuccess(state, action) {
      state.isLoading = false;
      state.listAcademicYears = action.payload;
    },
    getClassSuccess(state, action) {
      state.isLoading = false;
      state.listClass = action.payload;
    },
    getStudentSuccess(state, action) {
      state.isLoading = false;
      state.listStudents = action.payload;
    },
    getDataMazerSucces(state, action) {
      state.isLoading = false;
      state.dataMazer = action.payload;
    },
    updateStudentStatus(state, action) {
      state.listStudents = state.listStudents?.map((student) => {
        if (student.student_id === action.payload.student_id) {
          return { ...student, leadership: action.payload.leadership };
        }
        return student;
      });
    },
    resetDataMazer (state)  {
      state.listStudents = []
    },
  },
});

export function setFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSucces(params));
  };
}
export function ResetDataMazer() {
  return async (dispatch) => {
    dispatch(slice.actions.resetDataMazer());
  };
}

export function getAcademicYears(schoolId) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`get-all-academicdate/${schoolId}`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(res);
      if (res?.status === 200) {
        dispatch(slice.actions.getAcademicYearSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function getClass(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`get-schoolyear-all-class`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(res);
      if (res?.status === 200) {
        dispatch(slice.actions.getClassSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function getStudent(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`get-student-in-class`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.status === 200) {
        dispatch(slice.actions.getStudentSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function assignClassMonitor(params) {
  return async () => {
    try {
      const res = await axiosInstance.post(`set-class-monitor`, params, {
        headers: {
          APPKEY: appKey,
        },
      });

      if (res?.status) {
        dispatch(slice.actions.updateStudentStatus({ student_id: params?.student_id, leadership: 1 }));
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
}
export function removeClassMonitor(params) {
  return async () => {
    try {
      const res = await axiosInstance.post(`remove-class-monitor`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.status) {
        dispatch(slice.actions.updateStudentStatus({ student_id: params?.student_id, leadership: 0 }));
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
}

// create
// add
// reset

export default slice.reducer;
