import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';

// utils
import { isEmpty } from 'lodash';
import axiosInstance from '../../utils/axios';
//
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter: {
    // academicYear: null,
    // level: null,
    // class: null,
  },
  academicYearData: [],
  levelData: [],
  classData: [],
  QRCodeData: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSuccess(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getAcademicYearSuccess(state, action) {
      state.isLoading = false;
      state.academicYearData = action.payload;
    },
    getLevelSuccess(state, action) {
      state.isLoading = false;
      //   console.log('academicYearData===>',action.payload)
      state.levelData = action.payload;
    },

    getClassSuccess(state, action) {
      state.isLoading = false;
      state.classData = action.payload;
    },

    getQRCodeSuccess(state, action) {
      state.isLoading = false;
      state.QRCodeData = action.payload;
    },
    resetDataStudentQRCode(state){
      state.QRCodeData = []
    }
  },
});

export function setFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function setSelectedFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function resetQRCodeData(){
  return async (dispatch) => {
    dispatch(slice.actions.resetDataStudentQRCode())
  }
}

export function GetAcademicYearData(schoolId) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`get-all-academicdate/${schoolId}`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(response);
      if (response?.data?.data) {
        dispatch(slice.actions.getAcademicYearSuccess(response?.data?.data));
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function GetLevelData(param) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`get-schoolyear-all-grade`, param, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(response);
      if (response?.data?.data) {
        dispatch(slice.actions.getLevelSuccess(response?.data?.data));
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function getQRCodeData(param, param2, param3, param4) {

  return async (dispatch) => {
    console.log('params4',param4)
    // try {
    //   const response = await axiosInstance.get(
    //     `print-qr-code-student-schoolyear?school_id=${param}&schoolyear_id=${param2}&level=${param3}&class_id=${param4}`,
    //     {
    //       headers: {
    //         APPKEY: appKey,
    //       },
    //     }
    //   );
    //   console.log(response);
    //   if (response?.data?.data) {
    //     dispatch(slice.actions.getQRCodeSuccess(response?.data?.data));
    //   }
    //   return response;
    // } catch (error) {
    //   console.log(error);
    //   return error;
    // }


    if (param4) {
      // Construct the URL with query parameters
      const url = `print-qr-code-student-schoolyear?school_id=${param}&schoolyear_id=${param2}&level=${param3}&class_id=${param4}`;
      try {
        const response = await axiosInstance.get(url, {
          headers: {
            APPKEY: appKey,
          },
        });
        console.log(response);
        if (response?.data?.data) {
          dispatch(slice.actions.getQRCodeSuccess(response?.data?.data));
        }
        return response;
      } catch (error) {
        // Handle any errors that occur during the API request
        console.error("Error:", error);
        return null; // You may want to handle the error more gracefully
      }
    } else {
      // Construct the URL without the 'class_id' parameter
      const url = `print-qr-code-student-schoolyear?school_id=${param}&schoolyear_id=${param2}&level=${param3}`;
      try {
        const response = await axiosInstance.get(url, {
          headers: {
            APPKEY: appKey,
          },
        });
        console.log(response);
        if (response?.data?.data) {
          dispatch(slice.actions.getQRCodeSuccess(response?.data?.data));
        }
        return response;
      } catch (error) {
        // Handle any errors that occur during the API request
        console.error("Error:", error);
        return null; // You may want to handle the error more gracefully
      }
    }
  };
}

export function getClassData(param) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`get-schoolyear-all-class`, param, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (response?.data?.data) {
        dispatch(slice.actions.getClassSuccess(response?.data?.data));
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export default slice.reducer;
