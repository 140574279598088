import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter: {
    comment : ''
  },
  eventDataAll: [],
  participantData: [],

  // In Class
  listEvent: [],
  listGuardianInClass : [],
  data : {
    event : null,
  }
};

const slice = createSlice({
  name: 'guardianPresenceSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSuccess(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    setDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getEventDataSuccess(state, actions){
      state.isLoading = false;
      state.eventDataAll = actions.payload;
    },
    getParticipantData(state, actions){
      state.isLoading = false;
      state.participantData = actions.payload;
    },
    getGuardianData(state, actions){
      state.isLoading = false;
      state.listGuardianInClass = actions.payload;
    },
    resetData(state){
      state.eventDataAll = []
    },
    resetParticipant(state){
      state.participantData = []
    },
    resetDataListGuardian(state){
      state.listGuardianInClass = []
    },
    createEventSuccess(state, actions){
      state.isLoading = false;
      state.participantData = state.participantData?.map(guardian => {
        if(guardian?.guardain_id === actions.payload.guardian_id){
          return {...guardian, paid: actions.payload.status, pay_at_event:actions.payload.pay_at_event}
        }
        return guardian
      });
    },
    addComment(state, actions){
      state.isLoading = false;
      
      state.participantData = state.participantData?.map(guardian => {
        if(guardian?.guardain_id === actions.payload.guardian_id){
          return {...guardian, event_comment: actions.payload.event_comment}
        }
        return guardian
      });
    },
    getListEvent (state, action){
      state.isLoading = false;
      state.listEvent = action.payload;
    },
    deleteEvent(state, action){
      state.isLoading = false;
      state.eventDataAll = state.eventDataAll.filter((item) => {
        return item?.id !== action?.payload?.event_id
      })
    },
    editEvent(state, action){
      state.isLoading = false;
      const oldData = state.eventDataAll;
      const newData  = oldData.map((item) => {
        if(item?.id === action?.payload?.id){
          return action.payload
        }
        return item
      })
      state.eventDataAll = newData;
      console.log('Event Data ==>',newData)
    }

  },
});

export function setFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}
export function setData(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setDataSuccess(params));
  };
}

export function setSelectedFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function getEventData(schoolId) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`get-school-event?school_id=${schoolId}`, {
        headers: {
          APPKEY: appKey,
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      });
      if (response?.data?.data) {
        dispatch(slice.actions.getEventDataSuccess(response?.data?.data));
      }
      return response;
    } catch (error) {
      return error;
    }
  };
}

export function getParticipantData(schoolId, param) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`get-all-guardian-event-attandance?school_id=${schoolId}&event_id=${param}`, {
        headers: {
          APPKEY: appKey,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      });
      if (response?.data?.data) {
        dispatch(slice.actions.getParticipantData(response?.data?.data||[]));
      }
      return response;
    } catch (error) {
      return error;
    }
  };
}

export function resetEventData(){
  return async (dispatch) => {
    dispatch(slice.actions.resetData())
  }
}

export function resetParticipantData(){
  return async (dispatch) => {
    dispatch(slice.actions.resetParticipant())
  }
}

export function resetDataGuardian(){
  return async (dispatch) => {
    dispatch(slice.actions.resetDataListGuardian())
  }
}

export function addPaymentGuardian(params){
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const respone = await axiosInstance.post(`adminAddGuardianPayment`, params, {
        headers: {
          APPKEY : appKey
        }
      });
      if(respone?.data?.data) {
        dispatch(slice.actions.createEventSuccess({guardian_id:params?.guardian_id, paid: respone?.data?.data?.status === "paid", pay_at_event:respone?.data?.data?.pay_at_event }));
      }
      return respone;
    } catch (error) {
      return error;
    }
  }
}
export function addCommentGuardian(params){
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const respone = await axiosInstance.post(`create-event-attendance-cmt`, params, {
        headers: {
          APPKEY : appKey
        }
      });
      if(respone?.data?.data) {
        dispatch(slice.actions.addComment({guardian_id:respone?.data?.data?.guardain_id, event_comment: respone?.data?.data?.event_comment}));
      }
      return respone;
    } catch (error) {
      return error;
    }
  }
}

// Get Guardian In class
export function getListEventData(schoolId) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`get-school-event?school_id=${schoolId}`, {
        headers: {
          APPKEY: appKey,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      });
      if (response?.data?.data) {
        dispatch(slice.actions.getListEvent(response?.data?.data));
      }
      return response;
    } catch (error) {
      return error;
    }
  };
}
export function ListGuardianInClass(params){
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const respone = await axiosInstance.post(`get-event-report`, params, {
        headers: {
          APPKEY : appKey
        }
      });
      if(respone?.data?.data) {
        dispatch(slice.actions.getGuardianData(respone?.data?.data));
      }
      dispatch(slice.actions.stopLoading());
      return respone;
    } catch (error) {
      return error;
    }
  }
}
export function deleteEventSchool(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`delete-school-event`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      if (res?.data?.status === true) {
        dispatch(slice.actions.deleteEvent(params));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function editEventSchool(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`create-edit-school-event`,params,{
        headers :{
            APPKEY:appKey
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.editEvent(res?.data?.data));
        console.log('Response Data ===>', res?.data?.data)
      }
      console.log("asdfdf", params)
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export default slice.reducer;