import PropTypes from 'prop-types';
// @mui
import { Typography, Stack, Hidden, Box, Paper } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (

    // <StyledRoot>
    //   <Logo
    //     sx={{
    //       zIndex: 9,
    //       position: 'absolute',
    //       mt: { xs: 1.5, md: 5 },
    //       ml: { xs: 2, md: 5 },
    //     }}
    //   />

    //   <StyledSection>
    //     <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
    //       {title || 'Hi, Welcome back'}
    //     </Typography>

    //     <Image
    //       disabledEffect
    //       visibleByDefault
    //       alt="auth"
    //       src={illustration || '/assets/illustrations/illustration_dashboard.png'}
    //       sx={{ maxWidth: 720 }}
    //     />

    //     <StyledSectionBg />
    //   </StyledSection>

    //   <StyledContent>
    //     <Stack sx={{ width: 1 }}> {children} </Stack>
    //   </StyledContent>
    // </StyledRoot>
  <Paper>
    <StyledRoot>
        <Hidden mdDown>
          <Paper elevation={3} sx={{m:2, width:'50%'}}>
            <StyledSection>
              <Logo 
                sx={{
                  display: 'flex',
                  justifyContent: 'center'}}
              />
              <Typography variant="h4" sx={{ mb: 6, maxWidth: 480, textAlign: 'center' }}>
                {title || 'CAMEMIS SYSTEM'}
              </Typography>

              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={illustration || '/assets/illustrations/Secure.png'}
                sx={{ maxWidth: 300 }}
              />

              <StyledSectionBg />
            </StyledSection>
          </Paper>
        </Hidden>
        <StyledContent>
          <Box>
            <Paper 
              sx={{p:3}} 
            >
              <Stack sx={{ width: 1 }}> {children} </Stack>
            </Paper>
          </Box>
        </StyledContent>
    </StyledRoot>
  </Paper>
  );
}
