import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios'
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  students: [],
  optionSchool: [],
  optionAcademicYear: [],
  optionGradStudent: [],
  selectedFilter: {
    // Block Search Student
    schools: null,
    academicYear: null,
    grades: null,
    date: null,
    firstName: '',
    lastName: '',
    codeStudent: '',

    // Block search student and guardian
    guardianId: null,
    studentId: null,
    school:null,
    grade:null,
    firstNameStudent: '',
    lastNameStudent: '',
    codeNumberStudent: '',
    academicYears: null,
  },
  filter:{
    school: null,
    academicYear: null,
    firstName: '',
    lastName: '',
  },
  searchData: [],
  searchDataStudent: [],

  // Guardian Start
  guardian: [],
  selectFilterGuardian: {
    school: null,
    firstName: '',
    lastName: '',
    codeGuardian: '',
  },
  searchDataGuardian: [],
  // Guardian Start

  // searchStudents: null,
};

const slice = createSlice({
  name: 'guardian',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getStudentListSucess(state, action) {
      state.isLoading = false;
      state.students = action.payload;
    },
    getSchoolNameSuccess(state, action) {
      state.isLoading = false;
      state.schoolNameSearch = action.payload;
    },
    getSchoolYearSuccess(state, action) {
      state.isLoading = false;
      state.schoolYearSearch = action.payload;
    },
    getSchoolSuccess(state, action) {
      state.optionSchool = action.payload;
    },
    getAcademicYearSuccess(state, action) {
      state.optionAcademicYear = action.payload;
    },
    getGradStudent(state, action) {
      state.optionGradStudent = action.payload;
    },
    getStudentSuccess(state, action) {
      state.isLoading = false;
      state.searchData = action.payload;
    },
    searchStudentSuccess(state, action) {
      state.isLoading = false;
      state.searchDataStudent = action.payload;
    },
    setFilterSuccess(state, action) {
      state.selectedFilter = action.payload;
    },
    setFilterSucces(state,action) {
      state.filter = action.payload;
    },

    getGuardianListSucess(state, action) {
      state.isLoading = false;
      state.guardian = action.payload;
    },
    getSearchGuardianSuccess(state, action) {
      state.isLoading = false;
      state.searchDataGuardian = action.payload;
    },
    setFilterGuardianSuccess(state, action) {
      state.selectFilterGuardian = action.payload;
    },
    handleSetFilterStudent(state,action){
      state.filter = action.payload;
    },
    resetData(state) {
      state.searchData = [];
      state.searchDataGuardian = [];
    },
    resetDataStudent(state) {
      state.searchDataStudent = [];
    },
    resetDataGuardianEdit(state, action) {
      state.searchDataStudent = action.payload;
    },
    // storeData(state, action) {
    //   getStudentSuccess(),
    //   getSearchGuardianSuccess(),
    // },
    // storeData()

    createGuardianSuccess(state, action) {
      const newValue = state.searchData?.map((item) => {
        if (item?.id === action.payload?.student_id) {
          return { ...item, guadian: [action.payload] };
        }
        return item;
      });
      state.searchData = newValue;
    },
  },
});

export function onSetFilterStudent (body) {
  return async (dispatch) => {
    dispatch(slice.actions.handleSetFilterStudent(body));
  }
}
export function setFilter () {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSucces());
  }
}

export function resetSearchData() {
  return async (dispatch) => {
    dispatch(slice.actions.resetData());
  };
}
export function ResetDataStudent() {
  return async (dispatch) => {
    dispatch(slice.actions.resetDataStudent());
  };
}

export function setSelectedFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params))
  };
}

export function setGuardianEditSuccess(params) {
  return async (dispatch) => {
    dispatch(slice.actions.resetDataGuardianEdit(params))
  };
}

export function setSelectedGuardianFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterGuardianSuccess(params));
  };
}

export function getAcademicYears(schoolId) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(
        `get-all-academicdate/${schoolId}`
      );
      console.log(res);
      if (res?.status === 200) {
        dispatch(slice.actions.getAcademicYearSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function searchStudent(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(
        `check-student-has-guardian`,
        params,
        {
          headers: {
            APPKEY: appKey,
          },
        }
      );
      console.log(res);
      if (res?.data?.data) {
        dispatch(slice.actions.getStudentSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function searchStudentCorrect(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(
        `check-student-has-guardian`,
        params,
        {
          headers: {
            APPKEY: appKey,
          },
        }
      );
      console.log(res);
      if (res?.data?.data) {
        dispatch(slice.actions.searchStudentSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function addGuardianToStudent(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(
        `add-student-to-guadian`,
        params,
        {
          headers: {
            APPKEY: appKey,
          },
        }
      );
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function searchGuardian(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(
        `check-guardian-has-student`,
        params,
        {
          headers: {
            APPKEY: appKey,
          },
        }
      );
      console.log(res);
      if (res?.data?.data) {
        dispatch(slice.actions.getSearchGuardianSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function resetGuardianPassword(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`reset-guadian-pw`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function resetStudentPassword(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`reset-student-pw`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function createGuardian(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`create-new-guardian`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(res);
      dispatch(slice.actions.createGuardianSuccess({ ...res?.data?.data, student_id: params?.student_id}));
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function editGuardian(params) {
  console.log(params);
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`guardian/edit-guardian_pf`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      dispatch(slice.actions.createGuardianSuccess({ ...res?.data?.data}));
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

// ===============================================

// search
export function getStudentSearch(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post('check-student-has-guardian', params);
      dispatch(slice.actions.getStudentListSucess(response?.data?.students));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSchoolYearSuccess(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('get-all-academicdate', id);
      dispatch(slice.actions.getSchoolYearSuccess(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getGradStudentSuccess(id){
  let res = '';
  return async(dispatch) => {
    // dispatch(slice.actions.startLoading());
    try{
       res = await axiosInstance.post('get-schoolyear-all-grade', id,{
        headers: {
          APPKEY: appKey,
        },
      })
    if (res?.status === 200){
      dispatch(slice.actions.getGradStudent(res?.data?.data))
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error))
  }
  return res;
}
}
// create
// add
// reset

export default slice.reducer;
