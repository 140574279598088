import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axiosInstance from '../../utils/axios'
//
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error:false,
  filter: {
    dateTC : null,
    // Academic year
    yearsTC : null,
    academicYearsTC : null,
    academic : '',
    amount: '',

    // Teacher
    nameTeacher: null,
    class : null,
    nameStudent: null,
  },
  customerPrice : [],
  listCommisionPlan: [],
  // Form create
  listYearsTC : [],
  listAllCommission : [],
  listStudents : [],
  listTeachers : [],
};

const slice = createSlice({
  name: 'feeSetting',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSuccess(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getCustomerPrice(state,action) {
        state.isLoading = false;
        state.customerPrice = action.payload
    },
    getListYears(state,action) {
        state.isLoading = false;
        state.listYearsTC = action.payload
    },

    getlistAllCommission(state,action) {
        state.isLoading = false;
        state.listAllCommission = action.payload
    },
    setListStudent:(state,action)=>{
      state.isLoading = false;
      state.listAllCommission = action.payload
    },
    // setListStudent:(state,action)=>{
    //   const item = action.payload;
    //   state.isLoading = false;
    //   const data= state.listAllCommission.find(v=>v.staff_id === item.staff_id);

    //   if(!data) {
    //     state.listAllCommission = [...state.listAllCommission,item]
    //     console.log(item)
    //   }else{
    //     data.item = {...data?.item,item}
    //     console.log(item)
    //   }
    // },
    updatePriceStatus(state, action){
      state.customerPrice = state.customerPrice?.map(price => {
        if(price.type === action.payload.type){
          return {...price, amount: action.payload.amount}
        }
        return price;
      })
    },
    getCommisionPlan(state,action) {
        state.isLoading = false;
        state.listCommisionPlan = action.payload
    },
    deleteCommisionPlan(state, action) {
      const newValue = state.listCommisionPlan?.filter(item => 
        item?.type !== action.payload?.type
      );
      state.listCommisionPlan = newValue;
    },
    createCommisionPlan(state, action) {
      state.listCommisionPlan = action.payload
    },
    getListTeacher(state, action) {
      state.isLoading = false;
      state.nameTeacher = action.payload
    },
    getListStudents(state, action) {
      state.isLoading = false;
      state.listStudents = action.payload
    },
    resetDataCommisionPlan(state){
      state.listCommisionPlan = []
    },
    resetDataListAllCommision(state){
      state.listAllCommission = []
    }
  },
});

export function setFilter (params) {
    return async (dispatch) => {
      dispatch(slice.actions.setFilterSuccess(params));
    }
}
export function ResetDataCommisionPlan () {
    return async (dispatch) => {
      dispatch(slice.actions.resetDataCommisionPlan());
    }
}
export function ResetDataListAllCommision () {
    return async (dispatch) => {
      dispatch(slice.actions.resetDataListAllCommision());
    }
}


export function getCustomerPriceSuccess(guid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.get(`get-customer-price/${guid}`,{
        headers :{
          APPKEY:appKey
        }
      });
        if (res?.data?.data) {
          dispatch(slice.actions.getCustomerPrice(res?.data?.data));
        }
        console.log('res', res)
        return res;
      } catch (error) {
          console.log(error);
          return error;
      }
    };
}

export function editCustomerPrice (params) {
    return async (dispatch) => {
        // dispatch(slice.actions.startLoading())
        try{
            const res = await axiosInstance.post(`edit-customer-price`,params, {
                headers :{
                    APPKEY:appKey
                }
              })
              if(res?.status){
              dispatch(slice.actions.updatePriceStatus({type:params?.type, amount:params?.amount}))
            }
            return res;
        }catch (error){
            console.log(error);
            return error;
        }
    }
}


// Teacher commision plan start
export function CommisionPlan(guid) {
  return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const res = await axiosInstance.get(`get-commission-plan/${guid}`,{
          headers :{
              APPKEY:appKey
          }
        });
        if (res?.data?.data) {
          dispatch(slice.actions.getCommisionPlan(res?.data?.data));
        }
        return res;
      } catch (error) {
          console.log(error);
          return error;
      }
    };
}

export function DeleteCommisionPlan(params) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`delete-commission-plan`,params,{
        headers :{
            APPKEY:appKey
        }
      });
      if (res?.data?.data) {
        dispatch(slice.actions.deleteCommisionPlan(params));
      }
      return res;
    } catch (error) {
        console.log(error);
        return error;
    }
  };
}

export function CreateCommisionPlan(params) {
    return async (dispatch) => {
      // dispatch(slice.actions.startLoading());
      try {
        const res = await axiosInstance.post(`add-commission-plan`,params,{
          headers :{
              APPKEY:appKey
          }
        });
        if (res?.status) {
          dispatch(slice.actions.createCommisionPlan(res?.data?.data));
        }
        return res;
      } catch (error) {
          console.log(error);
          return error;
      }
    };
}

// Teacher Commision start
export function getSchoolYearSuccess(id) {
  return async () => {
    try {
      const response = await axiosInstance.get(`get-all-academicdate/${id}`, {
        headers :{
          APPKEY:appKey
        }
      });
      if(response?.data?.data){
        dispatch(slice.actions.getListYears(response?.data?.data));
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}
export function listAllCommissionSuccess(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post(`query-all-list-commission`,params, {
        headers :{
          APPKEY:appKey
        }
      });
      if(response?.data?.data){
        dispatch(slice.actions.getlistAllCommission(response?.data?.data));
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}
export function getListTeachers(params) {
  return async () => {
    try {
      const response = await axiosInstance.post(`list-teacher-commission`,params,{
        headers :{
          APPKEY:appKey
        }
      });
      if(response?.data?.data){
        dispatch(slice.actions.getListTeacher(response?.data?.data));
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}
export function getListStudents(params) {
  return async () => {
    try {
      const response = await axiosInstance.post(`list-student-payment`,params,{
        headers :{
          APPKEY:appKey
        }
      });
      if(response?.data?.data){
        dispatch(slice.actions.getListStudents(response?.data?.data));
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}
export function createTeacherCommisionPlan(params) {
  return async () => {
    try {
      const response = await axiosInstance.post(`create-student-commission-payment`,params,{
        headers :{
          APPKEY:appKey
        }
      });
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}
export default slice.reducer;