import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter: {
    startDate: '',
    endDate: '',
  },
  eventData: [],
  listYears : [],
  listClasses : [],
};

const slice = createSlice({
  name: 'CreateEventSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSucces(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getEventDataSucces(state, action) {
      state.isLoading = false;
      state.eventData = action.payload;
    },
    getAcademicYearSuccess(state, action) {
      state.isLoading = false;
      state.listYears = action.payload;
    },
    getClassSuccess(state, action) {
      state.isLoading = false;
      state.listClasses = action.payload;
    },
    
  },
});

export function setFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSucces(params));
  };
}


export function createEvent(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`create-edit-school-event`,params,{
        headers :{
            APPKEY:appKey
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getEventDataSucces(res?.data?.data));
      }
      console.log("asdfdf", params)
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function getYears(schoolId) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`get-all-academicdate/${schoolId}`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(res);
      if (res?.status === 200) {
        dispatch(slice.actions.getAcademicYearSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
export function getDataClass(params) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(`get-schoolyear-all-class`, params, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(res);
      if (res?.status === 200) {
        dispatch(slice.actions.getClassSuccess(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}



export default slice.reducer;