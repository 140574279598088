import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import guardianReducer from './slices/guardian';
import listExcelReducer from './slices/listExcel';
import totalPaymentReducer from './slices/totalPayment';
import staffReducer from './slices/staff';
import mazerReducer from './slices/mazer';
import feeSettingReducer from './slices/feeSetting';
import guardianActiveReducer from './slices/guardianActive';
import listSchoolsReducer from './slices/listSchools';
import studentQRCodeReducer from './slices/studentQRCode';
import teacherQRCodeReducer from './slices/teacherQRCode';
import teacherInstructorQRCodeReducer from './slices/teacherInstructorQRCode';
import classMonitorQRCodeReducer from './slices/classMonitorQRCode';
import parentsQRCodeReducer from './slices/parentsSlice';
import makeUpMoneyReducer from './slices/makeUpMoney';
import allReportReducer from './slices/allReportSlice';
import monthlyReportReducer from './slices/monthlyReportSlice';
import semesterReportReducer from './slices/semesterReportSlice';
import yearlyReportReducer from './slices/yearlyReportSlice';
import guardianPresenceReducer from './slices/guardianPresenceSlice';
import createEventReducer from './slices/createEventSlice';
import schoolRequestReducer from './slices/schoolRequest';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['listSchools'],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  guardian: guardianReducer,
  listExcel: listExcelReducer,
  totalPayment: totalPaymentReducer,
  staff: staffReducer,
  mazer: mazerReducer,
  feeSetting: feeSettingReducer,
  guardianActive: guardianActiveReducer,
  listSchools: listSchoolsReducer,
  studentQRCode: studentQRCodeReducer,
  teacherQRCode: teacherQRCodeReducer,
  teacherInstructorQRCode: teacherInstructorQRCodeReducer,
  classMonitorQRCode: classMonitorQRCodeReducer,
  parentsSlice: parentsQRCodeReducer,
  allReportSlice: allReportReducer,
  monthlyReportSlice: monthlyReportReducer,
  semesterReportSlice: semesterReportReducer,
  yearlyReportSlice: yearlyReportReducer,
  guardianPresenceSlice: guardianPresenceReducer,
  CreateEventSlice: createEventReducer,
  makeUpMoney: makeUpMoneyReducer,
  product: persistReducer(productPersistConfig, productReducer),
  schoolRequest: schoolRequestReducer,
});

export { rootPersistConfig, rootReducer };
