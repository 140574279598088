import { React, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Autocomplete, Box, Container, Grid, Paper, TextField, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';

// Redux
import {getSchoolDataSuccess, setFilter} from '../../redux/slices/listSchools'


export const FormListSchools = () => {
    const theme = useTheme()
    const {t,i18n} = useTranslation()
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const {listSchools, filter} = useSelector((state) => state.listSchools)

    // Endpoint
    const getSchool = async (params) => {
        setLoading(true);
        try {
          const res = await dispatch(getSchoolDataSuccess(params));
          if (isEmpty(res?.data?.data)) {
            enqueueSnackbar(i18n.language === 'kh'? 'ទិន្នន័យសាលាគឺទទេ!' : 'schools data is empty !', { variant: 'error' });
          }
          console.log('res', res)
        } catch (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
        setLoading(false);
    };

    // Handle change start
     const handleSetSchool = (val) => {
        const newVal = { ...filter, school: val};
        dispatch(setFilter(newVal));
    };

    // Use effect start
    useEffect(() => {
        getSchool();
    }, []);
    // Use effect end

  return (
    <div>       
        <Box sx={{ width: '100%', typography: 'body1',}}>
          {/* {JSON.stringify(filter?.school?.id)} */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper
                        elevation={6}
                    >
                        <Autocomplete
                            disabled={loading}
                            size="small"
                            value={filter?.school}
                            onChange={(e,val) => handleSetSchool(val)}
                            options={listSchools || []}
                            getOptionLabel={(option) =>
                                i18n.language === 'kh'
                                ? `${option.school_name}`
                                : `${option.school_name_en}`
                            }
                            renderInput={(params) => <TextField {...params} label={t('SCHOOL_DATA.SCHOOL')} />}
                        />
                    </Paper>
                </Grid>
        </Box>
    </div>
  )
}
