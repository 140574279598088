import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter: {
    academicYear: null,
    level: null,
    class: null,
  },
  academicYearData: [],
  QRCodeData: [],
  // levelData: [],
  //   GetClassData: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSuccess(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getAcademicYearSuccess(state, action) {
      state.isLoading = false;
      state.academicYearData = action.payload;
    },
    getLevelSuccess(state, action) {
      state.isLoading = false;
    //   console.log('academicYearData===>',action.payload)
      state.levelData = action.payload;
    },
    getQRCodeSuccess(state, actions){
      state.isLoading = false;
      state.QRCodeData = actions.payload
    },
    resetDataQRCode(state){
      state.QRCodeData = []
    }
  },
});

export function setFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function setSelectedFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function GetAcademicYearData(schoolId) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`get-all-academicdate/${schoolId}`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(response);
      if (response?.data?.data) {
        dispatch(slice.actions.getAcademicYearSuccess(response?.data?.data));
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

// export function getQRCodeData(param, param2) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem('accessToken');
//       // const accessToken = token !== null || token !== undefined ? JSON.parse(token) : {};
//       const accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGktc2VydmljZXMuY2FtZW1pcy1sZWFybi5jb21cL2FwaVwvdjFcL2FkbWluXC9sb2dpbiIsImlhdCI6MTY5NDY4MDYwOCwiZXhwIjoxNzI2MjE2NjA4LCJuYmYiOjE2OTQ2ODA2MDgsImp0aSI6Ik1oVVV2OUpRQWZSaVhKREEiLCJzdWIiOjEsInBydiI6IjVhMTkxZjgyNDJjZDhjZDBjNzJlMWE3ZmEzZTczMjNlNjVhZWJkOTkifQ.EOifY8nIYsGhGvRF7RBon23eEEeq_Ada61m87eE38C0"
//       // console.log('accessToken',accessToken)
//       const headers = {
//         APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         Authorization: `Bearer ${accessToken}`,
//       };
//       const response = await axiosInstance({
//         url: `print-qr-code-student-leadership?school_id=${param}&schoolyear_id=${param2}&grade_id`,
//         method: 'GET',
//         headers,
//         responseType: 'blob',
//       });
//       const type = response.headers['Content-Type'];
//       const blob = new Blob([response.data], {
//         type,
//         //   type: type,
//         encoding: 'UTF-8',
//       });
//       if (response.status === 200) {
//         const link = document.createElement('a');
//         link.href = window.URL.createObjectURL(blob);
//         link.download = 'class_monitor_qr_code.pdf';
//         link.click();
//       }
//       return response;
//     } catch (error) {
//       // dispatch(hasError(error));
//       return error;
//     }
//   };
// }

export function getQRCodeData(param, param2) {
  return async (dispatch) => {
    // Construct the URL with query parameters
    const url = `print-qr-code-student-leadership?school_id=${param}&schoolyear_id=${param2}&grade_id`;
    try {
      const response = await axiosInstance.get(url, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(response);
      if (response?.data) {
        dispatch(slice.actions.getQRCodeSuccess(response?.data));
      }
      return response;
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error('Error:', error);
      return null; // You may want to handle the error more gracefully
    }
  };
}

export function resetQRCodeData(){
  return async (dispatch) => {
    dispatch(slice.actions.resetDataQRCode())
  }
}

export default slice.reducer;
