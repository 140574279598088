import { async } from '@firebase/util';
import { saveAs } from 'file-saver';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axiosInstance from '../../utils/axios';
//
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  listAllClass: [],
  schoolYear: [],
  schoolRequest: [],
  paymentRequest: [],
  listRequestType: [],
  filterData: {
    school_id: null,
    schoolyear_id: null,
    class_id: null,
  },
};

const slice = createSlice({
  name: 'schoolRequest',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      console.log('loading', state.isLoading);
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    allClass(state, action) {
      state.listAllClass = action.payload;
    },
    schoolYear(state, action) {
      state.schoolYear = action.payload;
    },
    requestList(state, action) {
      state.schoolRequest = action.payload;
    },
    requestPayment(state, action) {
      state.paymentRequest = action.payload;
    },
    listRequestCompanyType(state, action) {
      state.listRequestType = action.payload;
    },
  },
});

export function getSchoolYearAllClass(data) {
  return async (dispatch) => {
    console.log('data', data);

    try {
      const res = await axiosInstance.post('/get-schoolyear-all-class', data, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log('res', res);
      dispatch(slice.actions.allClass(res?.data?.data));
    } catch (error) {
      console.log('error', error);
    }
  };
}

export function getAllSchoolYear(param) {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`/get-all-academicdate/${param}`);
      console.log('res', res);
      dispatch(slice.actions.schoolYear(res?.data?.data));
    } catch (error) {
      console.log('error', error);
    }
  };
}

export function getAllRequest(param) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const res = await axiosInstance.post(`/school-request/get-list-request`, param, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log('res', res);
      if (res?.data !== null) {
        dispatch(slice.actions.requestList(res?.data?.data));
        dispatch(slice.actions.stopLoading());
      } else {
        dispatch(slice.actions.stopLoading());
      }
    } catch (error) {
      console.log('error', error);
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function changeRequestStatus(param) {
  console.log('param', param);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const res = await axiosInstance.post(`/school-request/approve-request`, param, {
        headers: {
          APPKEY: appKey,
        },
      });

      dispatch(slice.actions.stopLoading());
      console.log('res', res);
      return res;
    } catch (error) {
      console.log('error', error);
      return null;
    }
  };
}

export function getPaymentRequestCompany(param) {
  // console.log('param', param);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const res = await axiosInstance.get(`/get-payment-request-company/${param}`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log('res', res);

      dispatch(slice.actions.requestPayment(res?.data?.data));
      dispatch(slice.actions.stopLoading());
      // return res?.data?.status || null;
    } catch (error) {
      console.log('error', error);
      // return null;
    }
  };
}

export function getListRequestType() {
  // console.log('param', param);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const res = await axiosInstance.get(`/list-request-company-types`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log('res', res);

      dispatch(slice.actions.listRequestCompanyType(res?.data?.data));
      // dispatch(slice.actions.stopLoading());
      // return res?.data?.status || null;
    } catch (error) {
      console.log('error', error);
      // return null;
    }
  };
}

export const onExportListRequestUsingFree = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const response = await axiosInstance({
      url: '/school-request/get-excel-list-request',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = response.headers['Content-Type'];
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'List Request using for free.xlsx';
      link.click();
    }
    return response;
  } catch (error) {
    // dispatch(hasError(error));
    return error;
  }
};

export const onExportListRequestUsingFreeAsPDF = (params) => async (dispatch, getstate) => {
  try {
    const headers = {
      APPKEY: appKey,
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    };
    const response = await axiosInstance({
      url: 'school-request/get-excel-list-request',
      method: 'POST',
      headers,
      responseType: 'blob',
      data: params,
    });
    const type = 'application/pdf';
    const blob = new Blob([response.data], {
      type,
      encoding: 'UTF-8',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'List Request using for free.pdf';
      link.click();
    }

    return response;
  } catch (error) {
    return error;
  }
};

export default slice.reducer;
