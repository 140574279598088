import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../utils/axios';
import { dispatch } from '../store';
import authHeader from '../../utils/authHeader';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error: false,
  filter: {
    academicYear: null,
    level: null,
    class: null,
  },
  academicYearData: [],
  QRCodeData: [],
  //   GetClassData: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSuccess(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getAcademicYearSuccess(state, action) {
      state.isLoading = false;
      state.academicYearData = action.payload;
    },
    getQRCodeSuccess(state, action) {
      state.isLoading = false;
      state.QRCodeData = action.payload;
    },
    resetDataQRCode(state){
      state.QRCodeData = []
    }
  },
});

export function setFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function resetQRCodeData(){
  return async (dispatch) => {
    dispatch(slice.actions.resetDataQRCode())
  }
}

export function setSelectedFilter(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSuccess(params));
  };
}

export function GetAcademicYearData(schoolId) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`get-all-academicdate/${schoolId}`, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(response);
      if (response?.data?.data) {
        dispatch(slice.actions.getAcademicYearSuccess(response?.data?.data));
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

// export function getQRCodeData(param, param2){
//   return async (dispatch) => {
//     const response = await axiosInstance.get(
//       `qr-code-instructor?school_id=${param}&schoolyear_id=${param2}`,
//       {
//         headers: {
//           APPKEY: appKey,
//         }
//       }
//     ).then((response) => response.blob())
//     .then((blob) => {
//       const ur; = URL.createObjectURL(blob);
//       setPdfUrl(URL);
//     });
//   }
// }

// export function getQRCodeData(param, param2) {
//   return async (dispatch) => {
//     //   try {
//     //     const response = await axiosInstance.get(
//     //       `qr-code-instructor?school_id=${param}&schoolyear_id=${param2}`,
//     //       {
//     //         headers: {
//     //           APPKEY: appKey,
//     //         },
//     //       }
//     //     );
//     //     // console.log(response);
//     //     return response;
//     //   } catch (error) {
//     //     // console.log(error);
//     //     return error;
//     //   }
//     // };
//     try {
//       const headers = {
//         "APPKEY": 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
//         "Content-Type":'application/json',
//         "Accept":"application/json",
//       };
//       const response = await axiosInstance({
//         url: `qr-code-instructor?school_id=${param}&schoolyear_id=${param2}`,
//         method: 'GET',
//         headers,
//         responseType: 'blob',
//         // data: {};

//       });
//       console.log(response)
//       const type = response.headers['Content-Type'];
//       const blob = new Blob([response.data], {
//         type,
//         encoding: 'UTF-8',
//       });
//       if (response.status === 200) {
//         const link = document.createElement('a');
//         link.href = window.URL.createObjectURL(blob);
//         link.download = 'QRCode_List.pdf';
//         link.click();
//       }
//       return response;
//     } catch (error) {
//       return error;
//     }
//   };
// }

// export const onExportAcademicStaffTemplate = args => async (
//   dispatch,
//   getstate
// ) => {
//   const headers = authHeader();
//   try {
//     const response = await axios({
//       url: API_URL + '/study-affairs-api/v1/staff/exportAcademicStaffTemplate',
//       method: 'POST',
//       headers: headers,
//       data: args,
//       responseType: 'blob'
//     });
//     const type = response.headers['content-type'];
//     const blob = new Blob([response.data], {
//       type: type,
//       encoding: 'UTF-8'
//     });

//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = 'Staff_Templete.xlsx';
//     link.click();
//     return response;
//   } catch (error) {
//     dispatch(slice.actions.hasError(error));
//     return error;
//   }
// };

// export function getQRCodeData(param, param2) {
//   return async (dispatch) => {
//     const headers = authHeader();
//     try{
//       const response = await axios({
//         url: `qr-code-instructor?school_id=${param}&schoolyear_id=${param2}`,
//         method: 'GET',
//         headers,
//         responseType: 'blob'
//       });
//       const type = response.headers['content-type'];
//       const blob = new Blob([response.data]);

//       const link = document.createElement('a');
//       link.href = window.URL.createObjectURL(blob);
//       link.download = 'QRCode_List.pdf'
//       link.click();
//       return response;
//     }catch (error) {
//       dispatch(slice.actions.hasError(error));
//       return error;
//     }
//   };
// }

// export function getQRCodeData(param, param2) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem('accessToken');
//       // const accessToken = token !== null || token !== undefined ? JSON.parse(token) : {};
//       const accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGktc2VydmljZXMuY2FtZW1pcy1sZWFybi5jb21cL2FwaVwvdjFcL2FkbWluXC9sb2dpbiIsImlhdCI6MTY5NDY4MDYwOCwiZXhwIjoxNzI2MjE2NjA4LCJuYmYiOjE2OTQ2ODA2MDgsImp0aSI6Ik1oVVV2OUpRQWZSaVhKREEiLCJzdWIiOjEsInBydiI6IjVhMTkxZjgyNDJjZDhjZDBjNzJlMWE3ZmEzZTczMjNlNjVhZWJkOTkifQ.EOifY8nIYsGhGvRF7RBon23eEEeq_Ada61m87eE38C0"
//       // console.log('accessToken',accessToken)
//       const headers = {
//         APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         Authorization: `Bearer ${accessToken}`,
//       };
//       const response = await axiosInstance({
//         url: `qr-code-instructor?school_id=${param}&schoolyear_id=${param2}`,
//         method: 'GET',
//         headers,
//         responseType: 'blob',
//       });
//       const type = response.headers['Content-Type'];
//       const blob = new Blob([response.data], {
//         type,
//         //   type: type,
//         encoding: 'UTF-8',
//       });
//       if (response.status === 200) {
//         const link = document.createElement('a');
//         link.href = window.URL.createObjectURL(blob);
//         link.download = 'teacher_instructor_qr_code.pdf';
//         link.click();
//       }
//       return response;
//     } catch (error) {
//       // dispatch(hasError(error));
//       return error;
//     }
//   };
// }

export function getQRCodeData(param, param2) {
  return async (dispatch) => {
    // Construct the URL with query parameters
    const url = `qr-code-instructor?school_id=${param}&schoolyear_id=${param2}`;
    try {
      const response = await axiosInstance.get(url, {
        headers: {
          APPKEY: appKey,
        },
      });
      console.log(response);
      if (response?.data) {
        dispatch(slice.actions.getQRCodeSuccess(response?.data));
      }
      return response;
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error('Error:', error);
      return null; // You may want to handle the error more gracefully
    }
  };
}

export default slice.reducer;
