import { async } from '@firebase/util';
import { createSlice } from '@reduxjs/toolkit';
// import { hasJSDocParameterTags } frimom 'typescript';
// import { create } from 'lodash';
// import AddStudent from 'src/view/Parents/DetailStudent/AddStudent';
// utils
import axiosInstance from '../../utils/axios'
//
import { dispatch } from '../store';

const appKey = 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE';

const initialState = {
  isLoading: false,
  error:false,
  filter:{
    academicYear: null,
    firstName: '',
    lastName: '',
    lastNameEn: '',
    firstNameEn: '',
  },
  DataStaff:[],
};

const slice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setFilterSucces(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },
    getDataStaffSucces(state, action) {
      state.isLoading = false;
      state.DataStaff = action.payload;
    },
    
  },
});

export function setFilter (params) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterSucces(params));
  }
}

export function dataStaff(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`search-staff`,params,{
        headers :{
            APPKEY:appKey
        },
      });
      if (res?.data?.data) {
        dispatch(slice.actions.getDataStaffSucces(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function editStaff(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axiosInstance.post(`edit-staff-pf`,params,{
        headers :{
            APPKEY:appKey
        },
      });
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function setStaffEditSuccess(params) {
  return async (dispatch) => {
    dispatch(slice.actions.getDataStaffSucces(params))
  };
}

export const onExportListStaffTemplate = (params) => async (dispatch, getstate) => {
  try { 
      const headers = {
          "APPKEY": 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
          "Content-Type":'application/json',
          "Accept":"application/json",
      };
      const response = await axiosInstance({
        url:'export-all-staff',
        method: 'POST',
        headers,
      //   headers: headers,
        responseType: 'blob',
        data: params
      });
      const type = response.headers['Content-Type'];
      const blob = new Blob([response.data], {
        type,
      //   type: type,
        encoding: 'UTF-8'
      });
      if (response.status === 200) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'List All Staff.xlsx';
        link.click();
      }
      return response;
  }catch (error) {
      // dispatch(hasError(error));
      return error
  }
}
// create
// add
// reset

export default slice.reducer;
